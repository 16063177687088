/**
 * DesktopSubnav
 *
 * A dropdown container that displays when a main nav link is hovered or focused.
 * Contains: Sections of nav links and nav content assets.
 */

import React, { ReactElement } from 'react'

import SwitchLink from '@lib/components/seo/SwitchLink'

import { navItem } from '@lib/helpers/navHelper/navHelper.models'
import DesktopNavAsset from './DesktopNavAsset'
import { unfocusThis } from '@lib/helpers/navHelper/navHelper.hooks'

interface DesktopSubnavProps {
	asset?: string
	handleNavClick?: () => void
	subnavItems: navItem[]
}

const DesktopSubnav = ({
	asset,
	handleNavClick,
	subnavItems,
}: DesktopSubnavProps) => {
	let allBrandsLink: null | ReactElement = null

	const renderSubnavItems = (subnavItems: navItem[]) => {
		return subnavItems
			.map((navItem) => {
				if (navItem.href) {
					// All brands link needs to be added to markup separately:
					if (navItem.href.indexOf('all-brands') > -1) {
						allBrandsLink = (
							<SwitchLink
								href={navItem.href}
								className="nav-link subnav__link subnav__link--bold subnav__link--margin"
								onClick={handleNavClick}
							>
								{navItem.name}
							</SwitchLink>
						)

						return null
					}

					// Links with one-off conditional styling:
					let className = 'nav-link subnav__link'

					if (navItem.href.indexOf('sale') > -1) {
						className += ' subnav__link--highlight'
					}

					// Markup:
					return (
						<li key={navItem.key} className="subnav__item">
							<SwitchLink
								href={navItem.href}
								className={className}
								onClick={unfocusThis}
								target={
									navItem.href.indexOf('https') > -1 ? '_blank' : undefined
								}
							>
								{navItem.name}
							</SwitchLink>
						</li>
					)
				}

				return null
			})
			.filter((navItem) => navItem != null)
	}

	return (
		<div className={'desktop-nav-item__dropdown subnav'}>
			<div className={'subnav__container app-max-width'}>
				<div className={'subnav__content'}>
					{/* Dropdown Categories */}
					{subnavItems.map((subNavItem) => (
						<div key={subNavItem.key}>
							<span
								className={'subnav__heading'}
								id={`subnav-${subNavItem.key}`}
							>
								{subNavItem.name}
							</span>
							<ul
								className={'subnav__links'}
								aria-describedby={`subnav-${subNavItem.key}`}
							>
								{subNavItem.vals && renderSubnavItems(subNavItem.vals)}
							</ul>
							{subNavItem.name.toLowerCase().indexOf('brand') > -1 &&
								allBrandsLink &&
								allBrandsLink}
						</div>
					))}
				</div>

				{asset && (
					<div className={'subnav__asset'}>
						<DesktopNavAsset assetId={asset} />
					</div>
				)}
			</div>
		</div>
	)
}

export default DesktopSubnav
