import React from 'react'

/* Components */
import SwitchLink from '@components/seo/SwitchLink'

/* Helpers */
import { routeHome } from '@helpers/routeHelper'

/* Props */
type BrandLogoProps = {
	className?: string
	isDark?: boolean
	isLink?: boolean
	variant: '176x100' | '48x49' | '116x68' | '60x35' | '100x60' | '181x102'
}

/* Guide
    - 176x100: desktop full header
    - 48x49: desktop mini header
    - 116x68: desktop footer

    - 60x35: mobile header
    - 100x60: mobile footer

    - 181x102: error page
*/

const BrandLogo = ({
	className,
	isDark = false,
	isLink = true,
	variant,
}: BrandLogoProps): React.ReactElement | null => {
	/* Dimensions */
	const [width, height] = variant.split('x')

	const logo = (
		<img
			src={`/image/logo-${variant}${isDark ? '__dark' : ''}.svg`}
			loading="lazy"
			alt={`The 1916 Company luxury watches for sale`}
			width={width}
			height={height}
			style={
				!className
					? {
							padding: '2px',
							width: width + 'px',
							height: height + 'px',
							maxWidth: width + 'px',
					  }
					: undefined
			}
			className={className}
		/>
	)

	return isLink ? (
		<SwitchLink href={routeHome()} aria-label={`Home`}>
			{logo}
		</SwitchLink>
	) : (
		logo
	)
}

export default BrandLogo
