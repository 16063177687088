import React from 'react'
import { useContentAsset } from '@lib/hooks/useContentAsset'
import parse from 'html-react-parser'
import Image from '@lib/core/image'

interface DesktopNavAssetProps {
	assetId: string
}

const DesktopNavAsset = ({ assetId }: DesktopNavAssetProps) => {
	const { asset } = useContentAsset(assetId)

	const parseAssetAndConvertImages = (asset: string): React.ReactElement => {
		const ret = parse(asset) as React.ReactElement
		const hasNoPictures: boolean =
			asset.indexOf('<picture') === -1 ? true : false
		if (hasNoPictures && ret && ret.props && ret.props.children) {
			let anyImages: boolean = false
			const newChildren = React.Children.map(ret.props.children, (child) => {
				const isImg: boolean = child.type === 'img'
				if (isImg) {
					anyImages = true
					return (
						<Image
							sizes={true}
							src={child.props.src}
							alt={child.props.alt || ''}
							width={488}
							height={310}
							className={`${'image'}`}
						/>
					)
				} else {
					return child
				}
			})
			if (anyImages) {
				return React.cloneElement(ret, { children: newChildren })
			}
		}
		return ret
	}

	if (!asset || !asset.body || asset.body.indexOf('<picture>') == -1) {
		return null
	} else {
		return <>{parseAssetAndConvertImages(asset.body)}</>
	}
}

export default DesktopNavAsset
