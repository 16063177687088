import React, { useEffect, useState } from 'react'

/* Components */
import SearchButton from '../SearchButton/SearchButton'
import SwitchLink from '@components/seo/SwitchLink'
import BrandLogo from '@components/logo/BrandLogo'
import InlineCSS from '@components/seo/InlineCSS'

/* Helpers */

import { unfocusThis } from '@helpers/navHelper/navHelper.hooks'

/* Styles */
import DesktopNavStyles from '!raw-loader!postcss-loader!sass-loader!./DesktopNav.module.scss'

/* Shared Types */
import { NavigationData } from '@lib/mock/nav.models'
import { navItem } from '@lib/helpers/navHelper/navHelper.models'

/* Data */
import navData from '@lib/data/pref-nsDesktopNav.json'
import DesktopSubnav from './DesktopSubnav'
import { useLocation } from 'react-router-dom'

const DesktopNav = ({
	type = 'full',
}: {
	data: NavigationData
	type?: 'full' | 'mini'
}) => {
	/* Hide nav on link click as if it were an SFRA navigation */
	const [hideNavs, setHideNavs] = useState(false)
	const location = useLocation()
	useEffect(() => {
		if (hideNavs) {
			setTimeout(() => {
				setHideNavs(false)
			}, 150)
		}
	}, [location])

	const handleNavClick = () => {
		unfocusThis()
		setHideNavs(true)
	}

	return (
		<InlineCSS componentName={'DesktopNav'} styles={DesktopNavStyles}>
			<nav
				className={`${'desktop-nav-items'} ${'desktop-nav-items--' + type} ${
					hideNavs ? 'desktop-nav-items--disable' : ''
				}`}
			>
				{type === 'mini' ? (
					<div className={`${'desktop-nav-items__left'}`}>
						<BrandLogo variant={`48x49`} isDark={true} />
					</div>
				) : null}

				{/* Top Nav */}
				{(navData as navItem[]).map((topNavItem) => (
					<div rawclassname="desktop-nav-item" key={topNavItem.key}>
						<SwitchLink
							href={topNavItem.href}
							aria-haspopup={true}
							rawclassname="nav-link desktop-nav-item__link"
							onClick={handleNavClick}
						>
							{topNavItem.name}
						</SwitchLink>

						{topNavItem.vals && (
							<DesktopSubnav
								asset={topNavItem.assetId}
								subnavItems={topNavItem.vals}
								handleNavClick={handleNavClick}
							/>
						)}
					</div>
				))}
				{type === 'mini' ? (
					<div className={`${'desktop-nav-items__right'}`}>
						<SearchButton type="icon" variant="primary" />
					</div>
				) : null}
			</nav>
		</InlineCSS>
	)
}

export default DesktopNav
