import React, {
	useContext,
	useEffect,
	useRef,
	useState,
	lazy,
	Suspense,
} from 'react'

/* Context */
import { SiteHeaderContext } from '../SiteHeaderProvider'

/* Components */
import { Icon } from '../../Icon/Icon'
import { Btn } from '../../Btn/Btn'
import BrandLogo from '@components/logo/BrandLogo'
import ShoppingBag from '../ShoppingBag/ShoppingBag'
import PromoBanner from '../PromoBanner/PromoBanner'
import InlineCSS from '@components/seo/InlineCSS'

/* Lazy Components */
const MobileAppBanner = lazy(() =>
	import('@components/banners/MobileAppBanner/MobileAppBanner').catch(() => {
		return { default: () => <></> }
	})
)

/* Hooks */
import { useDetectHuman } from '@hooks/useDetectHuman'

/* Shared Types */
import { TextItems } from '@lib/mock/nav.models'

/* Inline Styles */
import MobileBannerStyles from '!raw-loader!postcss-loader!sass-loader!./MobileBanner.module.scss'

const MobileBanner = ({ text }: { text: TextItems }) => {
	const { isMobileNavOpen, setIsMobileNavOpen, showSearch, setShowSearch } =
		useContext(SiteHeaderContext)

	const [isAppBannerVisible, setIsAppBannerVisible] = useState(false)

	/* Local Handlers */
	const toggleSearch = (): void => {
		setShowSearch(!showSearch)
	}
	const openNav = (): void => {
		setIsMobileNavOpen(true)
	}

	/* Refs */
	const mobilePlaceholder = useRef<HTMLDivElement>(null)
	const mobileBanner = useRef<HTMLDivElement>(null)

	/* Set mobile-placeholder to same height as mobile-banner */
	const useMatchHeight = (
		fromRef: React.RefObject<HTMLDivElement>,
		toRef: React.RefObject<HTMLDivElement>
	) => {
		/* Events to trigger height match */
		const renderEvents: string[] = ['resize', 'load']

		/* Set height of mobile-placeholder to current height of mobile-banner */
		const onRender = () => {
			if (fromRef?.current && toRef?.current) {
				fromRef.current.style.position = 'fixed'
				toRef.current.style.display = 'block'
				toRef.current.style.height = `${fromRef.current.clientHeight}px`
			}
		}

		/* Add event listeners */
		useEffect(() => {
			onRender()
			renderEvents.forEach((event) => window.addEventListener(event, onRender))
			return () => {
				renderEvents.forEach((event) =>
					window.removeEventListener(event, onRender)
				)
			}
		}, [])

		/* Update height on mobile-nav open/close */
		useEffect(() => {
			onRender()
		}, [isMobileNavOpen, isAppBannerVisible])
	}
	useMatchHeight(mobileBanner, mobilePlaceholder)

	/* Render on first interaction */
	const canRenderMobileAppBanner = useDetectHuman()

	return (
		<InlineCSS componentName={'MobileBanner'} styles={MobileBannerStyles}>
			<div
				ref={mobilePlaceholder}
				rawclassname={`mobile-placeholder`}
				className={`mobile-placeholder ${'placeholder'} ${
					isMobileNavOpen ? 'placeholder--hide' : ''
				}`}
				style={{
					display: 'none',
				}}
			/>
			<div
				ref={mobileBanner}
				style={{
					position: 'sticky',
				}}
				rawclassname={`mobile-banner`}
				className={`container ${isMobileNavOpen ? 'container--relative' : ''}`}
			>
				{canRenderMobileAppBanner && (
					<Suspense fallback={<></>}>
						<MobileAppBanner
							isAppBannerVisible={isAppBannerVisible}
							setIsAppBannerVisible={setIsAppBannerVisible}
						/>
					</Suspense>
				)}
				<div className={`mobile-banner`}>
					<div className={`mobile-banner__items mobile-banner__left`}>
						<Btn variant="icon" handleClick={openNav} ariaLabel={text.menu}>
							<Icon name={'menu'} size={'24'} title={text.menu} />
						</Btn>
					</div>
					<BrandLogo variant={`60x35`} isDark={true} />
					<div className={`mobile-banner__right`}>
						<ShoppingBag variant="primary" />
					</div>
				</div>

				<div className={`search`}>
					<Btn
						className={`search__button`}
						variant={`icon`}
						handleClick={toggleSearch}
						ariaLabel={text.search}
					>
						<div className={`search__bar`}>
							<Icon
								name={'navSearch'}
								size="20"
								title={text.search}
								className={`search__icon`}
							/>
							<span className={`search__text`}>{text.search}</span>
						</div>
					</Btn>
				</div>

				<PromoBanner variant="mobile" />
			</div>
		</InlineCSS>
	)
}

export default MobileBanner
