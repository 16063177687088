import { getContentAsset } from '@lib/helpers/ocapiCustomAPIHelper'
import { useEffect, useState } from 'react'

interface ContentAsset {
	online: boolean
	name: string
	pageTitle: string
	pageDescription: string
	body: string
	js: string
	css: string
}

export const useContentAsset = (assetId: string) => {
	const [asset, setAsset] = useState<ContentAsset | null>(null)

	const retrieveAsset = async () => {
		const dataAsset = await getContentAsset(assetId)

		if (dataAsset && dataAsset.online) {
			setAsset(dataAsset as unknown as ContentAsset)
		}
	}

	useEffect(() => {
		retrieveAsset()
	}, [])

	return {
		asset,
	}
}
